import { AfterContentChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { PatientAppointmentService } from '../../services/patient-appointment.service';
import { PopoverController } from '@ionic/angular';
import { SelfSchedulingLogoutComponent } from '../../components/self-scheduling-logout/self-scheduling-logout.component';
@Component({
  selector: 'self-scheduling-header',
  templateUrl: './self-scheduling-header.component.html',
  styleUrls: ['./self-scheduling-header.component.scss'],
})
export class SelfSchedulingHeaderComponent implements OnInit, OnDestroy, AfterContentChecked {

  tenantId: any;
  helpLineNumber: any;
  isHelpine: boolean = false;
  tenantName: any = '';
  appointmentType: any;
  patientEmail: any;
  isExistingUser: boolean = false;
  breakpoint: any = window.matchMedia('(min-width: 991px)');
  isDesktop: boolean;

  constructor(private commonService: CommonService, private appointmentService: PatientAppointmentService, private popOverCtrl: PopoverController) {
    this.isDesktop = !!this.breakpoint.matches;
  }

  async ngOnInit() {
    this.tenantId = await this.commonService.getSessionStorage('tenant');
    this.appointmentType = await this.commonService.getSessionStorage('appointmentType');
    this.patientEmail = this.commonService.getSessionStorage('patientEmail');
  }

  ngAfterContentChecked() {
    this.tenantName = this.commonService.getSessionStorage('tenantName');
    this.helpLineNumber = this.appointmentService.formatPhoneNumber(this.commonService.getSessionStorage('helpLineNumber'));
    if (this.helpLineNumber) {
      this.isHelpine = true;
    } else {
      this.isHelpine = false;
    }
    if (this.patientEmail) {
      this.isExistingUser = true;
    } else {
      this.isExistingUser = false;
    }
  }

  async ngOnDestroy() {
    this.closePopover();
  }

  async popOver(e: Event) {
    const popOvers = await this.popOverCtrl.create({
      component: SelfSchedulingLogoutComponent,
      event: e,
      cssClass: 'custom-popover',
    })
    await popOvers.present();
  }

  async closePopover() {
    try {
      await this.popOverCtrl.dismiss();
    } catch (error) {
      if (error.message === 'overlay does not exist') {
        console.warn('Popover already dismissed or not present.');
      } else {
        console.warn('Error dismissing popover:', error);
      }
    }
  }

}