import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, ErrorHandler } from '@angular/core';
import { ToasterService } from './toaster.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private toasterService: ToasterService) {
    // You can initialize any required services here
  }
  handleError(error: any, context?: string): void {
    // Log the error (e.g., to the console or a remote logging service)
    console.error('Global Error Handler:', error, context);
    this.toasterService.showError('Unexpected error, Please try again later or contact the practice');
    // Handle specific error types
    if (error instanceof TypeError) {
      console.error('Type Error:', error.message);
    } else if (error instanceof HttpErrorResponse) {
      console.error('HTTP Error:', error.message);
    } else {
      console.error('An unknown error occurred:', error.message);
    }
  }
}