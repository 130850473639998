import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { AuditLog, AuditLogPg } from '../models/audit-log.interface';

@Injectable({
  providedIn: 'root'
})
export class AuditLogService {

  public georesult: any;
  public ip: string = "";
  public user: string = "";
  public ClientBrowser: string;
  public fullname: string = "";
  public userid: string = '';
  public country: string = '';
  public countryCode: string = '';
  public continent: string = '';
  public region: string = '';
  public city: string = '';
  public tenantid: string = '';
  public patientid: string = '';
  public workflow: string = '';

  constructor(private commonService: CommonService) { }

  generateAuditLog(value: string, tenantId: string, name: string, providerId: string, patientId: string): AuditLog | null {
    let sActivityType: string;
    let sDescription: string;

    switch (value) {
      case 'appointmentBooked':
        sActivityType = 'appointmentBooked';
        sDescription = 'Appointment Added';
        break;
      case 'quickActivate':
        sActivityType = 'quickActivate';
        sDescription = 'Patient Portal Quick Activation';
        break;
      case 'demographicsUpdated':
        sActivityType = 'demographicsUpdated';
        sDescription = 'Demographic details updated';
        break;
      case 'insuranceUpdated':
        sActivityType = 'insuranceUpdated';
        sDescription = 'Insurance details updated';
        break;
      case 'insuranceAdded':
        sActivityType = 'insuranceAdded';
        sDescription = 'New insurance added';
        break;
      case 'cardsAdded_INSURANCE':
        sActivityType = 'cardsAdded';
        sDescription = 'New insurance card added';
        break;
      case 'cardsAdded_LICENSE':
        sActivityType = 'cardsAdded';
        sDescription = 'New driving license card added';
        break;
      case 'cardsDeleted_INSURANCE':
        sActivityType = 'cardsDeleted';
        sDescription = 'Insurance card deleted';
        break;
      case 'cardsDeleted_LICENSE':
        sActivityType = 'cardsDeleted';
        sDescription = 'Driving license deleted';
        break;
    }

    return {
      tenantid: tenantId,
      sActivityModule: "Appointment",
      sActivityCategory: "Self Scheduling",
      sActivityType,
      sDescription,
      nProviderID: providerId,
      nPatientID: patientId
    };
  }

  generateAuditLogForPg(value: string, name: string, tenantId: string, patientId: string): AuditLogPg | null {
    let message: string;
    let module: string;
    let screen: string;
    let useraction: string;
    let transactionid: string;

    switch (value) {
      case 'appointmentBooked':
        message = `Appointment booked successfully by ${name}`;
        module = 'Self Scheduling';
        screen = 'confirm-appointment';
        useraction = 'Clicked on book appointment button';
        transactionid = '';
        break;
      case 'quickActivate':
        message = 'Patient Portal Account Created from Self-Scheduling App';
        module = 'Self Scheduling';
        screen = 'booked-appointment';
        useraction = 'Clicked on book appointment button';
        transactionid = '';
        break;
      case 'patientLogin':
        message = `${name}, logged in`;
        module = 'Self Scheduling';
        screen = 'patient-appointment';
        useraction = 'Clicked on Find Appointment';
        transactionid = '';
        break;
      case 'patientLogout':
        message = `${name}, logged out`;
        module = 'Self Scheduling';
        screen = 'patient-appointment';
        useraction = 'Clicked on Logout Button on the profile dropdown';
        transactionid = '';
        break;
      case 'demographicsUpdated':
        message = `${name}, updated demographic details`;
        module = 'Self Scheduling';
        screen = 'patient-scheduling/patient-info-popup';
        useraction = 'Updated & confirmed demographics changes';
        transactionid = '';
        break;
      case 'insuranceUpdated':
        message = `${name} updated insurance details`;
        module = 'Self Scheduling';
        screen = 'patient-scheduling/patient-insurance-popup';
        useraction = 'Updated & confirmed insurance changes';
        transactionid = '';
        break;
      case 'insuranceAdded':
        message = `${name} added new insurance`;
        module = 'Self Scheduling';
        screen = 'patient-scheduling';
        useraction = 'Clicked on add new insurance button';
        transactionid = '';
        break;
      case 'cardsAdded_INSURANCE':
        message = `${name} added new insurance card photo`;
        module = 'Self Scheduling';
        screen = 'patient-scheduling';
        useraction = 'Clicked on View/Update Insurance Card Photo';
        transactionid = '';
        break;
      case 'cardsAdded_LICENSE':
        message = `${name} added new driving card photo`;
        module = 'Self Scheduling';
        screen = 'patient-scheduling';
        useraction = 'Clicked on View/Update Driving Card Photo';
        transactionid = '';
        break;
      case 'cardsDeleted_INSURANCE':
        message = `${name} deleted insurance card photo`;
        module = 'Self Scheduling';
        screen = 'patient-insurance-card-edit';
        useraction = 'Clicked on Delete Icon on patient-insurance-card-edit';
        transactionid = '';
        break;
      case 'cardsDeleted_LICENSE':
        message = `${name} deleted driving card photo`;
        module = 'Self Scheduling';
        screen = 'patient-scheduling';
        useraction = 'Clicked on Delete Icon on patient-insurance-card-edit';
        transactionid = '';
        break;
      case 'patientForgotPassword':
        message = `${name} updated patient portal password`;
        module = 'Self Scheduling';
        screen = 'patient-scheduling';
        useraction = 'Clicked on Forgot password link';
        transactionid = '';
        break;
      default:
        return null;  // Return null if the value doesn't match
    }

    this.user = this.commonService.getSessionStorage('patientEmail');

    return {
      application: 'Self-Scheduling',
      auditlogcode: '',
      city: this.city,
      clientbrowser: this.getBrowser() || '',
      clientip: this.ip,
      continent: this.continent || '',
      country: this.country || '',
      countrycode: this.countryCode || '',
      groupid: '',
      loginuser: this.user || this.commonService.getSessionStorage('Email'),
      message: message,
      module,
      organizationid: '',
      outcome: '',
      region: this.region || '',
      screen,
      transactionid,
      useraction: value,
      userfullname: name,
      userid: this.userid,
      patientid: patientId,
      tenantid: tenantId
    };
  }

  getBrowser() {
    let browserName: string;
    const agent = window.navigator.userAgent.toLowerCase()
    if (agent.indexOf('edge') > -1) { console.log("edge"); browserName = "edge"; }
    else if (agent.indexOf('opr') > -1 && !!(<any>window).opr) { console.log('opera'); browserName = "Opera"; }
    else if (agent.indexOf('chrome') > -1 && !!(<any>window).chrome) { browserName = "Chrome" }
    else if (agent.indexOf('trident') > -1 && !!(<any>window).ie) { console.log('ie'); browserName = "Internet  Explorer" }
    else if (agent.indexOf('firefox') > -1 && !!(<any>window).firefox) { console.log('firefox'); browserName = "Firefox" }
    if (agent.indexOf('safari') > -1 && !!(<any>window).safari) { console.log('safari'); browserName = "Safari" }
    return browserName;
  }

  getBrowserData(georesult: any, name: any) {
    this.georesult = georesult;
    if (Object.keys(georesult).length != 0) {
      this.ip = georesult.query;
      this.country = georesult.country;
      this.countryCode = georesult.countryCode;
      this.continent = georesult.continent;
      this.region = georesult.region;
      this.city = georesult.city;
    }
    this.user = name;
  }

}
