import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, EMPTY } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { PatientAppointmentLoginService } from '../../services/patient-appointment-login.service';
import { AuditLogService } from '../../services/audit-log.service';
import { PatientAppointmentService } from '../../services/patient-appointment.service';

@Component({
  selector: 'forgot-password-popup',
  templateUrl: './forgot-password-popup.component.html',
  styleUrls: ['./forgot-password-popup.component.scss'],
})
export class ForgotPasswordPopupComponent implements OnInit {

  forgotPasswordForm: FormGroup;
  showMsg: boolean = false;
  email: any;
  tenantId: any;
  tenantName: any;

  constructor(private loginService: PatientAppointmentLoginService, private commonService: CommonService, private toasterService: ToasterService,
    private auditLogService: AuditLogService, private appointmentService: PatientAppointmentService) {
    this.createForm();
  }

  createForm() {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9.!#$&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$"),
      Validators.maxLength(50)])
    });
  }

  ngOnInit() {
    this.tenantId = this.commonService.getSessionStorage('tenant');
    this.tenantName = this.commonService.getSessionStorage('tenantName');
  }

  disableResetLink: boolean = false;
  isProcessing: boolean = false;
  async sendPasswordLink() {
    // Check if the form is valid and not currently processing
    if (this.forgotPasswordForm.valid && !this.isProcessing) {
      this.email = this.forgotPasswordForm.value.email;
      this.isProcessing = true; // Set processing flag to true

      this.loginService.ifEmailExistToResetPassword(this.email, this.tenantId)
        .pipe(
          catchError((error: any) => {
            this.showMsg = false;
            this.disableResetLink = false;
            this.isProcessing = false; // Reset processing flag on error
            this.toasterService.showError('Error fetching email');
            return EMPTY;
          })
        ).subscribe((res: any) => {
          if (res && res?.data && res?.message === "Email Exist") {
            this.showMsg = true;
            this.disableResetLink = true; // Disable reset link to prevent multiple clicks
            let nPatientID = res?.data.nPatientID;

            // Prevent multiple send attempts by setting isProcessing to true
            this.loginService.sendMailIfResetPassword(nPatientID, this.tenantId)
              .pipe(
                catchError((error) => {
                  this.toasterService.showError('Failed to send reset password link');
                  this.isProcessing = false; // Reset processing flag on error
                  return EMPTY;
                })
              ).subscribe(() => {
                this.isProcessing = false; // Reset processing flag once done
                this.saveAuditLogsToPg('patientForgotPassword');
              });
          } else {
            this.showMsg = true; // Show true even if email not found
            this.disableResetLink = true;
            this.isProcessing = false; // Reset processing flag for non-existent email
          }
        });
    }
  }

  saveAuditLogsToPg(value: string) {
    let tenantId = this.commonService.getSessionStorage('tenant');
    let patientId = this.commonService.getSessionStorage('patientId');
    let name = this.commonService.getSessionStorage('fn');

    const parts = patientId.split('-');
    const trimmedValue = parts[0];

    const auditData = this.auditLogService.generateAuditLogForPg(value, name, tenantId, trimmedValue);
    if (auditData) {
      this.appointmentService.saveAuditLogsToPg(auditData)
        .pipe(
          catchError((error) => {
            console.log(error);
            return EMPTY;
          })
        ).subscribe(); // No action needed for the response
    }
  }

  resendPasswordLink() {
    this.showMsg = false;
    this.forgotPasswordForm.reset();
    this.disableResetLink = false;
    this.isProcessing = false;
  }

}
