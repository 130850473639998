<div class="patient_schedule_main scroll_main">
  <div class="ion-header-bg-color header" *ngIf="isTablet || isDesktopDevice">
    <div class="row-new text-center mid-header-section px-30 ">
      <div class="col-8-lg col-6-md text-center d-flex align-items-center justify-content-center">
        <div class="column">
          <ion-text color="gray80" class="bodyL-txt-large pb-3 ion-text-nowrap"><span
              class="">{{tenantName}}</span></ion-text>
          <!-- <ion-text color="gray80" class="bodyS-txt pt-3">Our tagline is the best tagline for you</ion-text> -->
        </div>
      </div>
      <div class="col-4-lg col-6-md">
        <img src="../../../../assets/svg/Banner Image.svg" class="ml-5 img-fluid banner_img" />
      </div>
    </div>
  </div>

  <div class="ion-header-bg-color headermobile" *ngIf="isMobileHeader">
    <self-scheduling-header></self-scheduling-header>
  </div>

  <div class="scroll_body mobile_scroll">
    <div class="container schedule_main">
      <div class="schedule_title text-center py-10 px-50">
        <ion-text class="h1-Montserrat-regular h3-Montserrat-mobile text-blue-color">Schedule an appointment</ion-text>
      </div>

      <div class="tablet-steps" *ngIf="isTablet || isMobile">
        <ion-text class="number-txt montserrat" color="gray80">
          It’ll take less than 5 minutes. To get started, just fill in the info below and press Find Appointment
        </ion-text>
        <!-- <ion-text class="number-txt montserrat" color="gray80" *ngIf="welcomeTextMsg" [innerHTML]="sanitizedWelcomeText"></ion-text> -->
      </div>
      <div class="row-new px-20 py-10 justify-center">
        <!-- <div class="col-6-lg col-12-sm schedule_steps p-50" *ngIf="!isTablet && !isMobile && isWelcomeText">
          <div [innerHTML]="sanitizedWelcomeText"></div>
        </div> -->

        <div class="col-6-lg  col-12-sm schedule_steps p-50" *ngIf="!isTablet && !isMobile">
          <div class="text-center mb-25">
            <ion-text class="bodyXL-txt-bold text-blue-color">It’s as easy as 1, 2, 3!</ion-text>
          </div>
          <div class="schedule_steps_item d-flex mb-25">
            <div class="schedule_steps_count mr-15">
              <ion-text class="bodyL-txt-bold" color="highlight">1</ion-text>
            </div>
            <div class="column">
              <ion-text class="bodyL-txt text-blue-color mb-5">Select an appointment time</ion-text>
              <ion-text class="bodyM-txt" color="gray100">Just tell us why you're coming and which location and provider
                you want to visit.</ion-text>
            </div>
          </div>
          <div class="schedule_steps_item d-flex mb-25">
            <div class="schedule_steps_count mr-15">
              <ion-text class="bodyL-txt-bold" color="highlight">2</ion-text>
            </div>
            <div class="column">
              <ion-text class="bodyL-txt text-blue-color mb-5">Tell us a little about yourself</ion-text>
              <ion-text class="bodyM-txt" color="gray100">Don’t worry, just the basics, it’ll take less than 5
                minutes.</ion-text>
            </div>
          </div>
          <div class="schedule_steps_item d-flex">
            <div class="schedule_steps_count mr-15">
              <ion-text class="bodyL-txt-bold" color="highlight">3</ion-text>
            </div>
            <div class="column">
              <ion-text class="bodyL-txt text-blue-color mb-5">Book it!</ion-text>
              <ion-text class="bodyM-txt" color="gray100">Our office will be notified, and you’ll receive a
                confirmation email.</ion-text>
            </div>
          </div>
        </div>

        <div class="col-6-lg col-6-md col-12-sm schedule_forms">
          <ion-content *ngIf="showLoader && (isDesktopDevice)" style="text-align: center;">
            <ion-label>Loading...</ion-label>
            <ion-spinner></ion-spinner>
          </ion-content>
          <div *ngIf="showLoader && (isTablet || isMobile)" style="text-align: center;">
            <ion-label>Loading...</ion-label>
            <ion-spinner></ion-spinner>
          </div>
          <kendo-tabstrip tabPosition="top" (tabSelect)="onSelect($event)">
            <kendo-tabstrip-tab title="New Patient"
              [selected]="this.selectedTab === 'New Patient'" *ngIf="newPatient">
              <ng-template kendoTabContent [formGroup]="newPatientLoginForm" autocomplete="off">
                <div class="mt-20">
                  <ion-text class="mb-3" class="bodyM-txt ion-text-uppercase mb-5" color="gray80">Appointment Type
                  </ion-text>
                  <ion-text class="text-lowercase italic" color="gray80">(Required)</ion-text>
                  <kendo-combobox [data]="allAppointmentTypes" [textField]="'appointmenttypedescription'"
                    [valueField]="'appointmentType'" placeholder="Appointment Type *" [suggest]="true"
                    class="bodyL-txt-medium" (valueChange)="onAppointmentTypeSelect($event)" [loading]="isLoading"
                    required formControlName="appointmentType">

                    <!-- Custom template for when data is being fetched -->
                    <ng-template kendoComboBoxNoDataTemplate *ngIf="isLoading">
                      <div class="loader-container">
                        <div class="spinner"></div> <!-- Spinner element -->
                        <p>Loading appointment types...</p>
                      </div>
                    </ng-template>
                    <!-- Template for when there's no data and not loading -->
                    <ng-template kendoComboBoxNoDataTemplate *ngIf="!isLoading && !allAppointmentTypes?.length">
                      No appointment types available.
                    </ng-template>
                  </kendo-combobox>
                </div>
                <div class="mt-20">
                  <ion-text class="mb-3" class="bodyM-txt ion-text-uppercase mb-5" color="gray80">Location</ion-text>
                  <kendo-combobox [data]="locations" [textField]="'locationdescription'" [valueField]="'location'"
                    placeholder="Any location" [suggest]="true" class="bodyL-txt-medium"
                    (valueChange)="onLocationChange($event)" formControlName="location">
                  </kendo-combobox>
                </div>
                <div class="mt-20">
                  <ion-text class="mb-3" class="bodyM-txt ion-text-uppercase mb-5" color="gray80">Provider</ion-text>
                  <kendo-combobox [data]="providers" [textField]="'providerName'" [valueField]="'provider'"
                    placeholder="Any provider" [suggest]="true" class="bodyL-txt-medium"
                    (valueChange)="onProviderChange($event)" formControlName="provider">
                  </kendo-combobox>
                </div>
                <div class="mt-20 mb-15">
                  <ion-text class="mb-3" class="bodyM-txt ion-text-uppercase mb-5" color="gray80">Time of Day</ion-text>
                  <kendo-buttongroup selection="single" class="w-100">
                    <button class="bodyM-txt" [selected]="this.selectedTime === ''" id="btn1" kendoButton [toggleable]="true"
                      (click)="onTimeButtonClick('all')">
                      Any time
                    </button>
                    <button class="bodyM-txt ion-text-capitalize" [selected]="this.selectedTime === 'am'" id="btn2"
                     kendoButton [toggleable]="true"
                      (click)="onTimeButtonClick('am')">
                      AM
                    </button>
                    <button class="bodyM-txt ion-text-capitalize" [selected]="this.selectedTime === 'pm'" id="btn3" kendoButton
                     [toggleable]="true"
                      (click)="onTimeButtonClick('pm')">
                      PM
                    </button>
                  </kendo-buttongroup>
                </div>
                <div *ngIf="errorMessageForNew" class="error_msg_login row  align-items-center">
                  <ion-icon class="mr-5 font16" src="../../../../assets/svg/alert-circle.svg"></ion-icon>
                  <ion-text color="error100" class="bodyS-txt">{{errorMessageForNewPatient}}.</ion-text>
                </div>

                <div class="py-10 text-center" *ngIf="isTablet || isDesktopDevice">
                  <ion-button fill="solid" color="ion-color-white" class="filled-icon-btn capitalize"
                    [disabled]="isButtonDisabled" (click)="findAppointmentSlots()">
                    <ion-text> Find Appointment</ion-text>
                    <ion-icon class="h3" src="../../../../../assets/svg/arrow-right.svg" class="ml-5"></ion-icon>
                  </ion-button>
                </div>

              </ng-template>
            </kendo-tabstrip-tab>

            <kendo-tabstrip-tab title="Returning Patient" [selected]="this.selectedTab === 'Returning Patient'"
              *ngIf="returningPatient">
              <ng-template kendoTabContent [formGroup]="loginForm" autocomplete="off">

                <!-- Decoy hidden inputs to trick browser autocomplete -->
                <input type="text" style="display: none" autocomplete="username" />
                <input type="password" style="display: none" autocomplete="current-password" />

                <div class="mt-20">
                  <kendo-textbox class="bodyL-txt-medium" placeholder="Username" formControlName="username"
                    color="gray100" (input)="resetErrorMsg()" name="login-username" autocomplete="username">
                  </kendo-textbox>
                </div>
                <div class="mt-20">
                  <kendo-textbox placeholder="Password" formControlName="password" class="bodyL-txt-medium"
                    #passwordInput="kendoTextBox" type="password" (input)="resetErrorMsg()" name="login-password"
                    autocomplete="new-password">
                  </kendo-textbox>
                </div>
                <div class="mt-20 mb-15">

                  <kendo-combobox [data]="allAppointmentTypes" [textField]="'appointmenttypedescription'"
                    [valueField]="'appointmentType'" placeholder="Appointment Type *" [suggest]="true"
                    class="bodyL-txt-medium" formControlName="appointmentType"
                    (valueChange)="onAppointmentTypeSelectforExisting($event)" [loading]="isLoading" required>

                    <!-- Custom template for when data is being fetched -->
                    <ng-template kendoComboBoxNoDataTemplate *ngIf="isLoading">
                      <div class="loader-container">
                        <div class="spinner"></div> <!-- Spinner element -->
                        <p>Loading appointment types...</p>
                      </div>
                    </ng-template>
                    <!-- Template for when there's no data and not loading -->
                    <ng-template kendoComboBoxNoDataTemplate *ngIf="!isLoading && !allAppointmentTypes?.length">
                      No appointment types available.
                    </ng-template>
                  </kendo-combobox>
                </div>
                <div *ngIf="ifErrorMessage || ifError" class="error_msg_login row align-items-center">
                  <ion-icon class="mr-5 font16" src="../../../../assets/svg/alert-circle.svg"></ion-icon>

                  <ion-text color="error100" class="bodyS-txt">{{errorMessage}}.</ion-text>

                  <ion-text color="error100" class="bodyS-txt"
                    *ngIf="loginForm.get('username').hasError('required') && !errorMessage">
                    Username or Email is required.
                  </ion-text>

                  <ion-text color="error100" class="bodyS-txt"
                    *ngIf="loginForm.get('username').hasError('maxlength') && !errorMessage">
                    Username or Email cannot exceed 50 characters.
                  </ion-text>
                </div>
                <div class="py-10 pb-15 text-center" *ngIf="isTablet || isDesktopDevice">
                  <ion-button fill="solid" color="ion-color-white" class="filled-icon-btn capitalize"
                    [disabled]="isButtonDisabled" (click)="getPortalLoginUserDetails()">
                    <ion-text> Find Appointment</ion-text>
                    <ion-icon class="h3" src="../../../../../assets/svg/arrow-right.svg" class="ml-5"></ion-icon>
                  </ion-button>
                </div>
                <div class="forgot_password text-center" *ngIf="isTablet || isDesktopDevice">
                  <ion-text class="bodyS-txt pointer" color="link" (click)="openForgotPassword()">Forgot
                    password?</ion-text>
                </div>

              </ng-template>
            </kendo-tabstrip-tab>
          </kendo-tabstrip>
        </div>
      </div>
    </div>
  </div>

  <div class="pt-25 text-center" *ngIf="noPracticeListError1 && noPracticeListError2 ">
    <div class="error_msg d-flex justify-content-center align-items-center pt-20">
      <div class="line-20"><ion-icon class="mr-5" src="../../../../assets/svg/alert-circle.svg"></ion-icon></div>
      <div class="line-20">
        <ion-text color="error100" class="bodyM-txt d-block">{{ noPracticeListError1 }}</ion-text>
        <ion-text color="error100" class="bodyM-txt">{{ noPracticeListError2 }}</ion-text>
      </div>
    </div>
    <br>
    <!-- <ion-button [disabled]="!loginForm.valid"   fill="solid" color="white" class="filled-icon-btn">
      <ion-text class="capitalize roboto">Open</ion-text>
      <ion-icon src="../../../../../../../assets/svg/arrow-right.svg" class="ml-10"></ion-icon>
    </ion-button> -->
  </div>

  <ion-footer *ngIf="isMobile" class="borderTopGray20 bg-white footer_main">
    <div class="py-10 px-30 text-center">
      <ion-button fill="solid" color="ion-color-white" class="filled-icon-btn capitalize w-100"
        (click)="findAppointmentSlots()" [disabled]="isButtonDisabled">
        <ion-text> Find Appointment</ion-text>
        <ion-icon class="h3" src="../../../../../assets/svg/arrow-right.svg" class="ml-5"></ion-icon>
      </ion-button>
    </div>
    <div class="pb-10 forgot_password text-center" *ngIf="isforgetPasswords">
      <ion-text class="bodyS-txt pointer" color="link" (click)="openForgotPassword()">Forgot password?</ion-text>
    </div>
  </ion-footer>
</div>


<div class="kendopopup forgot_password_popup">
  <kendo-dialog *ngIf="isForgotPasswordPopup" class="forgotpasspopup">
    <kendo-dialog-titlebar class="text-center">
      <div class="forgot_password_close_btn w-100 text-right">
        <ion-icon class="bodyXL-txt pointer" src="../../../../../assets/svg/x.svg" color="gray80"
          (click)="closeForgotPassword()"></ion-icon>
      </div>
    </kendo-dialog-titlebar>
    <forgot-password-popup></forgot-password-popup>
  </kendo-dialog>
</div>